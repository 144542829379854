<template>
  <div id="app">
      <router-view v-if="routerAlive"></router-view>
  </div>
</template>
<script>
export default {
    name: 'App',
    // 无感刷新
    provide() {
        return {
            reload: this.reload,
        }
    },
    data() {
        // 无感刷新参数
        return {routerAlive: true}
    },
    mounted() {
        // 关闭浏览器时 清除本地local值
        window.addEventListener('beforeunload', e => {
            window.localStorage.removeItem('user_lang')
        });
    },

    methods: {
        //重新加载的方法
        reload() {
            this.routerAlive = false
            this.$nextTick(function () {
                this.routerAlive = true
            })
        },
    },
}
</script>
<style>
body {
    margin: 0;
    padding: 0;
}

#app {
    margin: 0;
    padding: 0;
}
</style>
